/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
body, button, input, textarea {
  font-family: 'Press Start 2P', cursive; /* Use the font */
}


.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;
  width: 80%; 
  margin-left: auto; /* Centers the layout block horizontally */
  margin-right: auto; /* Centers the layout block horizontally */
}

.logo {
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  background: white;
  padding-top: 20px;  
}

.content {
  justify-content: center;
  padding: 20px;
}

.main-container {
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  padding-bottom: 20px;
}

.slider-container {
  max-width: 50%;
}

.side-container {
  max-width: 50%;
  text-align: center;
  margin: auto;
}

.footer {
  text-align: center;
  padding: 20px;
  background: white;
}

.image-slider {
  width: 80%;
  display: block;
  margin: auto;
  border-radius: 5%; /* Make the image round */
  border: 2px solid black; /* Add a black border */
  box-shadow: 0 0 20px 2px rgba(255, 215, 0, 0.8); /* Yellow glow effect */
  outline: 2px solid #FFF; /* White outline for high contrast */
}


.image-slider img {
  width: 100%;
  max-height: 300px;
  display: block;
  margin: auto;
}

.menu {
  font-size: large;
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  align-items: center;
}

.menu li {
  padding: 10px 20px;
}

.menu li a {
  text-decoration: none;
  color: black; /* Change as needed */
  font-weight: bold;
}

.twitter-icon{
  padding-right: 50px;
  padding-top: 10px;
  vertical-align: bottom;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .layout {
    width: 90%; 
  }

  .main-container {
    max-width: 100%;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .image-slider {
    width: 90%;
  }

  .slider-container, .side-container {
    max-width: 100%;
  }

  .menu {
    justify-content: space-around; /* Adjust menu spacing on mobile */
  }

  .twitter-icon {
    padding-right: 10px; /* Adjust right padding on mobile */
  }
}
